import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import FilmContent from '../components/contentBlocks/FilmContent';

const Film = () => {
  const { sanityFilm } = useStaticQuery(graphql`
    {
      sanityFilm {
        title
        trailerrImage {
          asset {
            url
          }
        }
        mainImage {
          asset {
            url
          }
        }
        metaDescription
        _rawFilmDescription
        _rawPoster
        trailerrFile {
          asset {
            url
          }
        }
        trailerrUrl
      }
    }
  `);
  return (
    <Layout
      title={sanityFilm.title}
      path="film/"
      pageImage={`${sanityFilm.mainImage.asset.url}?w=800`}
      description={sanityFilm.metaDescription}
    >
      <FilmContent {...sanityFilm} />
    </Layout>
  );
};

export default Film;
