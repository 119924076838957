import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import YouTubeVideo from '../helpers/YouTubeVideo';
import {
  colors,
  font,
  breakpoints,
  misc,
} from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import Modal from '../modules/Modal';
import { button } from '../../styles/utilities/elements';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.ipadLand });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.ipadLand - 1 });
  return isMobile ? children : null;
};

export const SinglePresentation = ({ title, url, date, subTitle, image }) => (
  <Presentation overrideImage={image} url={url.split('v=')[1]}>
    <Desktop>
      <Modal modalContent={<YouTubeVideo title="video" videoUrl={url} />}>
        <div />
      </Modal>
    </Desktop>
    <Mobile>
      <a href={url} target="_blank" rel="noreferrer">
        <div />
      </a>
    </Mobile>
    <h5>{title}</h5>
    {subTitle && <strong>{subTitle}</strong>}
    {date && (
      <>
        <br />
        {moment(date).format('LL')}
      </>
    )}
  </Presentation>
);

const Presentations = () => {
  const [limit, setLimit] = useState(4);
  const {
    allPresentations: { presentations },
  } = useStaticQuery(graphql`
    {
      allPresentations: allSanityPresentation(
        sort: { fields: publishedAt, order: DESC }
      ) {
        presentations: nodes {
          id
          title
          url: presentationUrl
          date: publishedAt
          subTitle
        }
      }
    }
  `);
  return (
    <SPresentations>
      <Wrapper>
        <div className="heading">
          <h2>Deandre&apos;s Presentations</h2>
          <Link to="/contact">Book DeAndre</Link>
        </div>
        <div>
          {presentations.slice(0, limit).map((props) => (
            <SinglePresentation key={props.id} {...props} />
          ))}
          {limit < presentations.length && (
            <div className="loadmore">
              <a
                href={null}
                onClick={() => {
                  setLimit(limit + 4);
                }}
              >
                Show Older Presentations
              </a>
            </div>
          )}
        </div>
      </Wrapper>
    </SPresentations>
  );
};

export default Presentations;

export const Presentation = styled.div`
  display: block;
  cursor: pointer;
  color: ${colors.white};

  &.active {
    cursor: auto;
  }

  > a {
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      background-color: ${colors.gold};
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.6;
      position: absolute;
      transition-duration: ${misc.animSpeed};
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;

      border-left: 50px solid ${colors.gold};
    }

    > div {
      background-image: url(${({ url, overrideImage }) =>
        url
          ? `https://i3.ytimg.com/vi/${url}/maxresdefault.jpg`
          : overrideImage});
      background-size: cover;
      background-position: center;
      padding-bottom: 50%;
      display: block;
      filter: grayscale();
      position: relative;
    }

    &:hover {
      &:before {
        @media screen and (min-width: ${breakpoints.ipadLand}px) {
          opacity: 0;
        }
      }
    }
  }

  h5 {
    font-family: ${font.primary};
    margin: 10px 0;
    font-weight: 600;
    color: ${colors.gold};
    text-transform: uppercase;
  }

  p {
    font-size: 20px;
    margin: 0;
  }
`;

const SPresentations = styled.div`
  padding: 60px 0 100px;

  .heading {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    h2 {
      color: ${colors.gold};
      display: inline-block;
      border-bottom: 4px solid ${colors.gold};
      margin-bottom: 20px;
      padding-bottom: 8px;
      line-height: 1.1;

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        margin-bottom: 50px;
      }
    }

    a {
      ${button};
      margin: 0 0 20px;

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        margin: 5px 0 0;
      }
    }
  }

  ${Wrapper} {
    > div {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    ${Presentation} {
      margin-bottom: 60px;
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: calc(50% - 30px);
      }
    }
  }

  .loadmore {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;

    a {
      ${button};
    }
  }
`;
