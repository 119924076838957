import React from 'react';
import styled from 'styled-components';
import { serializers } from './RichText';
import PortableText from '@sanity/block-content-to-react';
import Wrapper from '../../styles/utilities/Wrapper';
import ImageLoader from '../helpers/ImageLoader';
import { Presentation, SinglePresentation } from './Presentations';
import { breakpoints, colors } from '../../styles/utilities/settings';

const FilmContent = ({
  title,
  _rawPoster: poster,
  _rawFilmDescription: description,
  trailerrFile: {
    asset: { url: hostedTrailer },
  },
  mainImage: {
    asset: { url: bgImage },
  },
  trailerrImage: {
    asset: { url: trailerImage },
  },
  trailerrUrl,
}) => {
  return (
    <ScFilmContent>
      <Wrapper wider>
        <h1>{title}</h1>
        <div className="flex">
          <div className="poster">
            <ImageLoader src={poster} />
          </div>
          <div>
            <SinglePresentation
              title="Keep Rolling Trailer"
              url={trailerrUrl || hostedTrailer}
              image={trailerImage}
            />
            <div className="content">
              <PortableText
                blocks={description}
                projectId={process.env.GATSBY_SANITY_ID}
                serializers={serializers}
                dataset={process.env.GATSBY_SANITY_DATASET}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </ScFilmContent>
  );
};

export default FilmContent;

export const ScFilmContent = styled.div`
  color: ${colors.white};
  margin: 50px 0 100px;

  h1 {
    line-height: 1;
  }

  .content {
    font-size: 1.4em;
    margin-bottom: 100px;
  }

  ${Presentation} {
    margin-bottom: 20px;
  }

  .poster {
    margin-bottom: 40px;
  }

  .flex {
    display: flex;

    @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
      justify-content: space-between;
    }

    @media screen and (max-width: ${breakpoints.ipadPort}px) {
      flex-direction: column;
    }

    > div {
      @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
        width: calc(50% - 20px);
      }
    }
  }
`;
